.Auth{
    padding: 10px 4px 30px 4px;
    overflow-y: hidden;
}

.AuthHead{
    height: 50px;
    padding: 16px;
    text-align: center;
    background-color: rgba(33, 150, 243, 1);
    clip-path: polygon(0 0 ,100% 0, 100% 100%,0 70%);
    position: sticky;
    top: 0;
    left: 0;
    z-index: 20;

}

.AuthHead > img{
    height: 40px;
    margin-right: 8px;
}
.AuthTabs{
    margin-top: 8px;
}

.ModalCloseButton{
    font-weight: bold;
    background-color: rgba(33, 150, 243, 1);
    border: none;
    cursor: pointer;
    position: absolute;
    padding: 6px;
    top: 10px;
    right: 20px;
    font-size: 18px;
    color: white;
    z-index: 30;
}

.Logo{
    max-height: 50px;
    object-fit: cover;
    margin-top: 2px;
    animation: fade-in 0.5s ease-in-out;
    animation-delay: 0.1s;
    animation-fill-mode: backwards;
}


@keyframes fade-in {
    0% {opacity:0 ; }
    100% {opacity:1 ;}
}

@media (max-width:500px){
    .Logo{
        max-height: 46px;

    }
}

@media (max-width:400px){
    .Logo{
        max-height: 40px;

    }
}

@media (max-width:350px){
    .Logo{
        max-height: 32px;
        
    }
}
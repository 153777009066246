.CustomUrl{
    padding: 12px 16px;
    animation: fade 0.4s ease-in-out;
}

.CustomUrl > h4{
    margin: 16px 4px 25px 4px;
    font-weight: bolder;
    letter-spacing: 1px;
    color: #454545;
    word-spacing: 1px;
    font-size: 0.9em;
}
.CustomUrl > h5{
    font-size: 0.8em;
    color: #454545;
    font-weight: normal;
    text-align: center;
}

.CustomUrl > h5 > span{
    cursor: pointer;
    color: rgba(33, 150, 243, 1);
}
.CustomUrl > form > button{
    margin: 22px 0;
}
.CustomUrl > form > section{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.CustomUrl > form > section > button{
    display: block;
    margin-top: 12px;
}

@keyframes fade {
    from{opacity: 0;}
    to{opacity: 100%;}
}
.Dashboard {

}

.DataView{
    display: flex;
    overflow: hidden;

}
.DataView > aside{
    width: 35%;
    margin: 8px;
}

.Dashboard  > button{

    display: flex;
    align-items: center;
    justify-content: center;

}

.DataView > aside >  header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 3px;
    color: #454545;
}

.DataView > main{
    padding: 16px 20px;
    background-color: whitesmoke;
    transition: all 0.4s ease-in-out;
    width: 100%;
    overflow-y: auto;

}
@media (max-width: 700px) {

    .DataView > aside{
        width: 94%;
    }
    .DataView > main{
        width: auto;
        padding: 16px 6px;
        position: fixed;
        z-index: 200;
        transform: translateX(100%);
        left: 0;
        bottom: 0;
        right: 0;
        top: 0;
    }

}



.Login{
    font-family: "Segoe UI";
    padding: 20px 22px;
    animation: fade 0.4s ease-in-out;
}


@keyframes fade {
    from{opacity: 0;}
    to{opacity: 100%;}
}
.Login > h3{
    margin-bottom: 6px;
}

.Login > h4{
    margin-top: 6px;
    margin-bottom: 12px;
    color: gray;
    font-weight: normal;
}
.Login > form {
    padding: 16px 0px;
}

.Login > form > div{
    margin: 10px 2px;
}
.Login > form > button{
    margin: 10px 2px;
    width: 100%;
}

.LoginForgotPsd{
    text-align: center;
    padding: 4px;
    cursor: pointer;
}

@media (hover : hover) {
    .LoginForgotPsd:hover{
        color: rgba(33, 150, 243, 1);
    }
}
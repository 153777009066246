*{
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(122, 122, 123, 0.74);
  border-radius: 2px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(25, 118, 210, 0.5);
}

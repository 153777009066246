.Navigation{
    padding: 12px 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 8px #DDD;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
}

.NavigationItems{
    display: flex;
    margin-right: 6px;
    animation: fade-in 0.5s ease-in-out;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.NavigationItems > button {
    margin-right: 8px;
    margin-left: 8px;
    animation: fade-in 0.5s ease-in-out;
    animation-delay: 0.3s;
    animation-fill-mode: backwards;
}

@keyframes fade-in {
    0% {opacity:0 ; }
    100% {opacity:1 ;}
}


@media (max-width:500px){
    .Navigation{
        padding: 12px 8px;
    }
    .NavigationItems > button {
        margin-right: 6px;
        margin-left: 6px;
        font-size: 13px;
    }
}


@media (max-width:400px){
    .Navigation{
        padding: 8px 6px;
    }
    .NavigationItems > button {
        margin-right: 4px;
        margin-left: 4px;
        font-size: 12px;
    }
}


@media (max-width:350px){
    .Navigation{
        padding: 7px 4px;
    }
    .NavigationItems > button {
        padding: 3px;
        font-size: 10px;
    }
}